import React, {Component} from "react"
import { Link } from "react-router-dom";

class JobsView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Onze ijskarren zoeken chauffeurs!</h2><br />
                <p>
                Ben je student of kom je in aanmerking voor een flexistatuut? Doe je niets liever dan een glimlach toveren op ieders gezicht?
                </p>
                <p>
                Dan is het tijd om onze ijskarren te ontmoeten! Maurice, Alice, Colette, Oscar en Marcel zijn er alweer helemaal klaar voor om stad Deinze en omstreken komend seizoen te plezieren met de heerlijke ijsjes van Tartiste. Zit jij binnenkort aan hun stuur?
                </p>

                <b>Jobomschrijving:</b><br />
                <ul>
                    <li>Je rijdt vanaf +/- 12u30 met je ijskar uit volgens de vooraf bepaalde routes of reservaties op locatie</li>
                    <li>Je bereidt ijsjes en coupes en bedient de klanten aan de ijskar met een glimlach.</li>
                    <li>Je bent verantwoordelijk voor een correct beheer van de kassa</li>
                    <li>Op het einde van de dag laat je de ijskar proper en georganiseerd achter</li>
                    <li>Je werkdag eindigt gemiddeld tussen 21u en 23u</li>
                </ul>

                <b>Profiel:</b><br />
                <ul>
                    <li>Je bent student of je komt in aanmerking voor een flexistatuut</li>
                    <li>Je beschikt minstens 1 jaar over een rijbewijs B</li>
                    <li>Oriëntatie op de weg vormt voor jou geen probleem</li>
                    <li>Je kan goed functioneren op zelfstandige basis</li>
                    <li>Je werkt georganiseerd en houdt van efficiëntie en structuur</li>
                    <li>Je bent vriendelijk en klantgericht ingesteld</li>
                    <li>Je draagt de termen hygiëne, orde en netheid hoog in het vaandel</li>
                    <li>Lange dagen schrikken jou niet af</li>
                    <li>Mensen blij maken is je meest favoriete hobby</li>
                </ul>

                <b>Aanbod:</b><br />
                <ul>
                    <li>Je kan werken wanneer het jou past! Wekelijks of 2wekelijks vrij op dinsdag? Wisselend uurrooster op je werk of op school? Ook geen probleem! We plannen je in wanneer jij kan! Ook als je als student graag 1 maand in de zomer intensief wil werken, het kan allemaal!</li>
                    <li>We voorzien een theoretische en praktijkopleiding om van jou de perfecte scooper te maken</li>
                    <li>Variatie alom! Elke dag van de week is er een andere route en er zijn tal van reservaties voor verjaardagsfeestjes, burenfeestjes en bedrijfsbijeenkomsten.</li>
                    <li>Money, money, money</li>
                    <li>De heerlijkste ijsjes tijdens je lunchpauze</li>
                </ul>

                <p>
                    Begint het al te kriebelen?
                    <br />
                    Stuur je CV en motivatie naar info@tartiste.be of solliciteer via onderstaande knop.
                </p>

                <div class="text-center" style={{margin: "2rem"}}>
                    <a href="mailto:info@tartiste.be" className="btn btn-primary btn-rounded">SOLLICITEER NU!</a>
                </div>
                <br />
                <br />
            </div>
        )
    }
}

export default JobsView