import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import ErrorImage from '../ErrorImage';
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';

class NotFound extends Component {
    constructor() {
        super();
        this.state = {
        };
    }
    
    componentDidMount() {
    }
    
    render() {
        return (
            <main>
                <div className="content">
                    <ErrorImage text="404 Not found" subtext="Deze pagina kunnen we niet vinden"/>
                </div>
            </main>
        )
    }
}

export default NotFound