import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import {store} from "../../App";
import { setHideHeaderLinks } from "../../Redux/OverlayActions";

class AboutAppView extends Component {
    constructor() {
        super();
        this.state = {
            redirectToTracker: false,
        };

        this.acceptConsent = this.acceptConsent.bind(this);
    }

    acceptConsent() {
        localStorage.setItem('consent', true);
        store.dispatch(setHideHeaderLinks(false));
        this.setState({
            redirectToTracker: true,
        });
    }

    render() {
        const $versionInfo = window.versionInfo;

        if (this.state.redirectToTracker === true) {
			return <Redirect push to='/' />
		}

        const headAccept = ((window.localStorage.getItem('consent') || false) ? null : 
            <div>
                <h2>Welkom</h2>
                <p>Om deze app te gebruiken moet je akkoord gaan met onze <Link to="/privacypolicy">privacyverklaring</Link>.</p>
                <div className="text-center" style={{margin: "2rem"}}>
                    <a onClick={this.acceptConsent} className="btn btn-primary btn-rounded" style={{color: 'white'}}>Aanvaard de voorwaarden</a>
                </div>
            </div>
        );

        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/aboutapp/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                {headAccept}
                <h2>Over deze app</h2>
                <p>Welkom in de gloednieuwe Tartiste-app!</p>
                <p>Vanaf nu heb je de mogelijkheid om onze ijskarren op te roepen als hij bij jou in de buurt is!</p>
                <p>Opgepast!
                    <ul>
                        <li>Dit zal enkel mogelijk zijn als de ijskar zich binnen een straal van 1,5 km van jouw locatie bevindt</li>
                        <li>Als je de ijskar oproept biedt dit geen garantie. We doen onze uiterste best maar kunnen niets garanderen. Wil je 100% zekerheid dat de ijskar bij jou langskomt, ga dan naar <a href="https://reservatie.tartiste.be" target="_blank">https://reservatie.tartiste.be</a></li>
                    </ul>
                </p>
                <p>Op de kaart vind je nu ook de verschillende locaties terug waar je het overheerlijke Tartiste ijs kan kopen.</p>
                <p>Vragen of suggesties? Laat het ons weten via info@tartiste.be!</p>
                <p>Om deze app te gebruiken moet je akkoord gaan met onze <Link to="/privacypolicy">privacyverklaring</Link>.</p>
                <p>Buildversion: {$versionInfo} - App door ICEcorp (<a href="mailto:info@icecorp.be">info@icecorp.be</a>)</p>
            </div>
        )
    }
}

export default AboutAppView