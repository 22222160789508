import React, {Component} from "react"
import { Link } from "react-router-dom";

class AboutView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Onze ijskarren</h2><br />
                <p>
                Maak kennis met onze ijskarren Maurice, Alice, Colette, Oscar, Marcel, Julien, Celeste, Odiel & Juliette! Zij serveren heerlijk ambachtelijk roomijs die wij in ons eigen atelier maken met de beste ingrediënten. Je kan onze ijskarren spotten langs de regio's rond Deinze en omstreken. Feestje gepland? Dan kan je eenvoudig online een <Link to="/reserveren">ijskar reserveren</Link>.
                </p>
                <br />

                <p>
                    Reserveer <Link to="/reserveren">de ijskar op jouw feest</Link>
                </p>
                <p>
                    <Link to="/">Volg de ijskarren</Link>
                </p>
                <p>
                    <Link to="/status">Check waar en wanneer onze ijskarren rijden</Link>
                </p>
                <br />
                <br />
            </div>
        )
    }
}

export default AboutView