import React, {Component} from "react"

class MenuView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/menu/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>(N)ice-cream for everyone</h2><br />
                <b>Pick your favourite flavour!</b>
                <p>Onze ijskarren brengen ambachtelijk roomijs tot bij jou in 8 heerlijke smaken.</p>
                <ul>
                    <li>Vanille</li>
                    <li>Chocolade</li>
                    <li>Aardbei</li>
                    <li>Banaan</li>
                    <li>Stracciatella</li>
                    <li>Mokka</li>
                    <li>Pistache</li>
                    <li>Citroen</li>
                </ul>
                <br />

                <b>Kies je ijscoupe</b>
                <p>Onze ijskarren brengen ook twee klassieke roomijs-coupes tot bij je thuis.</p>
                <ul>
                    <li>Dame Blanche</li>
                    <li>Coupe Brésilienne</li>
                </ul>
                <br />

                <b>Selecteer je toppings</b>
                <p>Mag het iets meer zijn? <i>Guilty pleasures all the way...</i></p>
                <ul>
                    <li>Chocoladesaus</li>
                    <li>Caramelsaus</li>
                    <li>Aardbeisaus</li>
                    <li>Slagroom</li>
                    <li>Brésilliene-nootjes</li>
                </ul>
                <br />

                <b>Try us at home</b>
                <p>Krijg je geen genoeg van ons ijs?<br />
                Onze handige 1-liter verpakkingen zijn ook verkrijgbaar in de ijskar!</p>
            </div>
        )
    }
}

export default MenuView