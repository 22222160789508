import React, {Component} from "react"
import ErrorImage from '../ErrorImage';

class StatusView extends Component {
    constructor() {
        super();
        this.state = {
            hasInternet: true,
            karren: [],
        };

        // this.checkServerHeartbeat = this.checkServerHeartbeat.bind(this);
        this.fetchStatusinfo = this.fetchStatusinfo.bind(this);
        this.statusToText = this.statusToText.bind(this);
    }
    
    componentDidMount() {
        this.fetchStatusinfo();
        // this.checkServerHeartbeat();
    }

    // checkServerHeartbeat() {
    //     fetch("https://intranet.foubert.eu/api/frontend.php?action=get_heartbeat",{
    //         method: 'POST', 
    //     }).then(() => {
    //         this.setState({
    //             hasInternet: true,
    //         });
    //         setTimeout(this.fetchStatusinfo, 1000);
    //     }).catch(() => {
    //         this.setState({
    //             hasInternet: false,
    //         });
    //         setTimeout(this.checkServerHeartbeat, 1000);
    //     });
    // }

    fetchStatusinfo() {
        const url = process.env.REACT_APP_ICECORP_API_HREF+"v1/icecreamvanmarkerdata?company_id="+process.env.REACT_APP_COMPANY_ID;
        fetch(url)
        .then(response => response.json())
        .then(data => {
            const karren = data.data;
            console.log(karren);

            this.setState({
                karren: karren,
            });

            setTimeout(this.fetchStatusinfo, 1000);
        })
        .catch(() => {
            // setTimeout(this.checkServerHeartbeat, 1000);
            setTimeout(this.fetchStatusinfo, 1000);
        });
    }

    statusToText(kar) {
        switch (kar.status) {
            case "STARTUP":
                return <>Vertrekt zodadelijk{(kar.status_payload?.scheduled_at_name ? <> richting {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "EN_ROUTE_TO_ZONE":
                return <>Onderweg{(kar.status_payload?.scheduled_at_name ? <> naar {kar.status_payload?.scheduled_at_name}</> : <> naar zone</>)}</>;
                break;
            case "IN_ZONE":
                return <>Aan het rijden{(kar.status_payload?.scheduled_at_name ? <> in {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "EN_ROUTE_TO_DEPOT":
                return <>Uitverkocht</>;
                break;
            case "CLEANUP":
                return <>Uitverkocht</>;
                break;
            case "FINISHED":
                return <>Uitverkocht</>;
                break;
            case "SCHEDULED":
                return <>Gepland{(kar.status_payload?.scheduled_at_name ? <> in {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "NOT_SCHEDULED":
                return <>Niet gepland vandaag</>;
                break;
            case "EN_ROUTE_TO_ICECREAM_VAN_RESERVATION":
                return <>Onderweg naar reservatie</>;
                break;
            case "AT_ICECREAM_VAN_RESERVATION":
                return <>Op reservatie</>;
                break;
            case "ON_BREAK":
                return <>In pauze</>;
                break;
            default:
                return <></>;
                break;
        }
    }

    render() {
        const waarEnWanneer = <>
            <h2>Waar en wanneer?</h2><br />
            <b>Ontdek onze ijsroutes</b>
            <p>Onze ijskarren rijden in onderstaande regio’s. Opgelet! Door reservaties en oproepen kan de route van de ijskar afwijken. Thanks for understanding!</p>
            Maandag:
            <ul>
                <li>Sint-Denijs industrie/ Sint-Denijs Westrem/ Afsnee</li>
                <li>Prijkels/ Astene/ Petegem/ Deinze</li>
                <li>Zwijnaarde industrie/Zwijnaarde/ Zevergem/ De Pinte</li>
                <li>Deinze Noord/ Rond De Watertoren/ Dentergem/ Machelen</li>
                <li>Wondelgem industrie/ Durmakker/ Belzele/ Evergem/ Kerkbrugge</li>
                <li>Eeklo industrie/ Eeklo/ Lembeke</li>
                <li>Evergem industrie/ Wippelgem/ Kluizen/ Sleidinge/ Waarschoot</li>
                <li>Ter Mote/ Ruiselede Industrie/ Tielt Noord/ Markegem/ Wakken/ Oeselgem/ Sint-Baafs-Vijve/ Wielsbeke</li>
                <li>Tielt Zuid/ Meulebeke industrie/ Meulebeke/ Oostrozebeke</li>
            </ul>
            Dinsdag:
            <ul>
                <li>Nazareth industrie/ Sluis/ Eke/ Nazareth</li>
                <li>Drongen industrie/ Sint-Denijs industrie/ Drongen/ Baarle</li>
                <li>Prijkels/ Zeveren/ Vinkt/ Poeke/ Lotenhulle</li>
                <li>Lakeland/ Aalter/ Bellem</li>
                <li>Rond De Watertoren/ Gottem/ Grammene/ Wontergem/ Aarsele/ Poesele/ Meigem </li>
                <li>Deinze noord/ Kruisem industrie/ Kruisem/ Olsene/ Zulte</li>
                <li>Foreestelaan Industrie/ Merelbeke industrie/ Merelbeke/ Melle</li>
                <li>Wetteren industrie/Wetteren/ Schellebelle/ Serskamp</li>
                <li>Beernem industrie/ Beernem/ Sint-Joris/ Knesselare </li>
            </ul>
            Woensdag:
            <ul>
                <li>Landegem/ Merendree/ Hansbeke</li>
                <li>De Pinte</li>
                <li>Deinze/ Petegem/ Astene</li>
                <li>Zomergem/ Lovendegem/ Vinderhoute</li>
                <li>Ruiselede/ Tielt</li>
                <li>Asper/ Gavere/ Zingem</li>
                <li>Mariakerke/ Wondelgem</li>
                <li>Zevergem/ Zwijnaarde/ Sint-Denijs/ Afsnee </li>
                <li>Semmerzake/Vurste/ Melsen/ Schelderode/ Kwenenbos/ Bottelare</li>
            </ul>
            Donderdag:
            <ul>
                <li>Industrie Ruiselede/ Ter Mote/ Nevele/ Vosselare/ Leerne/ Bachte</li>
                <li>Sint-Denijs industrie/ Sint-Denijs Westrem/Afsnee</li>
                <li>Deinze industrie/ Zeveren/ Vinkt/ Poeke/ Lotenhulle</li>
                <li>Lakeland/ Aalter/ Bellem</li>
                <li>Tielt industrie/Aarsele/ Wontergem/ Grammene/ Gottem/ Poesele/ Meigem</li>
                <li>Wondelgem Industrie/ Durmakker/ Belzele/ Evergem/ Kerkbrugge</li>
                <li>Eeklo Industrie/ Eeklo/ Lembeke</li>
                <li>Evergem industrie/ Wippelgem/ Kluizen/ Sleidinge/ Waarschoot</li>
                <li>Beernem industrie/ Beernem/ Sint-Joris/ Knesselare</li>
            </ul>
            Vrijdag:
            <ul>
                <li>Zwijnaarde industrie/ Hansbeke/ Merendree/ Landegem</li>
                <li>Drongen industrie/ Sint-Denijs industrie/ Drongen/ Baarle</li>
                <li>Nazareth industrie/ Sluis/ Eke/ Nazareth</li>
                <li>Deinze noord/ Ter Mote/ Bilksken/ Vinderhoute/ Lovendegem/ Zomergem</li>
                <li>Prijkels/ Rond de watertoren/ Machelen/ Dentergem</li>
                <li>Legen Heirweg/ Gavere/ Asper/ Zingem</li>
                <li>Foreestelaan Industrie/ Merelbeke industrie (ambachtsweg)/ Melle/ Merelbeke</li>
                <li>Tielt Noord/ Markegem/ Wakken/ Oeselgem/ Sint-Baafs-Vijve/ Wielsbeke</li>
                <li>Tielt Zuid/ Meulebeke industrie/ Meulebeke/ Oostrozebeke</li>
            </ul>
            Zaterdag:
            <ul>
                <li>Meigem/ Poesele/ Nevele/ Vosselare/ Leerne/ Bachte</li>
                <li>Afsnee/ Sint-Denijs/ Zwijnaarde/ Zevergem </li>
                <li>De Pinte </li>
                <li>Deinze/ Petegem/ Astene</li>
                <li>Bellem/ Aalter</li>
                <li>Belzele/ Evergem/ Sleidinge</li>
                <li>Eeklo/ Waarschoot</li>
                <li>Mariakerke/ Wondelgem</li>
                <li>Semmerzake/ Vurste/ Melsen/ Schelderode/ Kwenenbos/ Bottelare</li>
            </ul>
            Zondag:
            <ul>
                <li>Landegem/ Merendree/ Hansbeke</li>
                <li>Baarle/ Drongen</li>
                <li>Nazareth/ Eke</li>
                <li>Zomergem/ Lovendegem/ Vinderhoute</li>
                <li>Tielt/ Ruiselede</li>
                <li>Kruisem/Olsene/Zulte</li>
                <li>Melle/ Merelbeke</li>
                <li>Wetteren/ Schellebelle/ Serskamp</li>
                <li>Knesselare/ Sint-Joris/ Beernem</li>
            </ul>
            <br />

            <h2>Van lente tot nazomer</h2><br />
            <b>Wanneer spot je onze ijskarren?</b>
            <p>Onze ijskarren rijden uit vanaf de eerste lentezon in maart tot de laatste nazomerdag half oktober. Alleen bij regenweer rijden we niet uit</p>
            <i>Because summer screams ice-cream...</i>
            <br />
            <br />
        </>;

        if (this.state.hasInternet) {
            if (this.state.karren.length > 0) {
                const overzicht = this.state.karren.map(kar => <tr>
                    <td style={{paddingRight: "20px"}}><img src={(kar.available ? "/images/tracker/icon_kar_available.png" : "/images/tracker/icon_kar_notavailable.png")} style={{width: "15px", marginRight:"5px"}}/><b>{kar.title}</b></td>
                    <td>{kar.available ? "Beschikbaar: " : "Niet beschikbaar: "} {this.statusToText(kar)}</td>
                </tr>);
                return (
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        <h2>Status</h2>
                        <p>Dit is de huidige statussen van onze ijskarren:</p>
                        <ul>
                            <table>
                                {overzicht}
                            </table>
                        </ul>

                        {waarEnWanneer}
                    </div>
                )
            } else {
                return (
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        <h2>Status</h2>
                        <p>De statussen wordt opgehaald...</p>

                        {waarEnWanneer}
                    </div>
                )
            }
        } else {
            return (
                <main>
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        {waarEnWanneer}
                    </div>
                    

                </main>
            )
        }
    }
}

export default StatusView