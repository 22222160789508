import React, {Component} from "react"
import { Link } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'

class TruckMarker extends Component {
    constructor() {
        super();

        this.statusToText = this.statusToText.bind(this);
    }

    componentDidMount() {
    }

    statusToText(kar) {
        switch (kar.status) {
            case "STARTUP":
                return <>Vertrekt zodadelijk{(kar.status_payload?.scheduled_at_name ? <> richting {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "EN_ROUTE_TO_ZONE":
                return <>Onderweg{(kar.status_payload?.scheduled_at_name ? <> naar {kar.status_payload?.scheduled_at_name}</> : <> naar zone</>)}</>;
                break;
            case "IN_ZONE":
                return <>Aan het rijden{(kar.status_payload?.scheduled_at_name ? <> in {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "EN_ROUTE_TO_DEPOT":
                return <>Uitverkocht</>;
                break;
            case "CLEANUP":
                return <>Uitverkocht</>;
                break;
            case "FINISHED":
                return <>Uitverkocht</>;
                break;
            case "SCHEDULED":
                return <>Gepland{(kar.status_payload?.scheduled_at_name ? <> in {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "NOT_SCHEDULED":
                return <>Niet gepland vandaag</>;
                break;
            case "EN_ROUTE_TO_ICECREAM_VAN_RESERVATION":
                return <>Onderweg naar reservatie</>;
                break;
            case "AT_ICECREAM_VAN_RESERVATION":
                return <>Op reservatie</>;
                break;
            case "ON_BREAK":
                return <>In pauze</>;
                break;
            default:
                return <></>;
                break;
        }
    }
    
    render() {
        const kar = this.props.kar;

        const myIcon = L.icon({
            iconUrl: (kar.available ? "/images/tracker/icon_kar_available.png" : "/images/tracker/icon_kar_notavailable.png"),
            iconSize: [28,28],
            iconAnchor: [14, 14],
            popupAnchor: [-0, -14],
            shadowUrl: (kar.available ? "/images/tracker/icon_kar_available.png" : "/images/tracker/icon_kar_notavailable.png"),
            shadowSize: [28,28],
            shadowAnchor: [14, 14],
        });

        return (
            <div className="iconhand">
                <Marker
                    icon={myIcon}
                    key={"karmarker"+this.props.kar.id}
                    onClick={(event) => {this.props.onClick(this.props.trackid, kar.latitude, kar.longitude);}}
                    position={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    >
                    <Popup>
                        <h6>IJskar {kar.title}</h6>
                        {kar.subtitle ? <p dangerouslySetInnerHTML={{__html: kar.subtitle}}></p> : null}
                        <p><strong>{kar.available ? "Beschikbaar" : "Niet beschikbaar"}</strong><br />{this.statusToText(kar)}</p>
                    </Popup>
                </Marker>
            </div>
        )
    }
}

export default TruckMarker