import React, {Component} from "react"
import { connect } from 'react-redux';

class OpenAppButton extends Component {
    constructor() {
        super();

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
    }

    handleClick() {
        this.props.handleMapClick();
        window.open('https://tracker.tartiste.be/', "_blank");
    }
    
    render() {
        const buttonSrc = 'images\\tracker\\icon_button.png';
        const buttonTextSrc = 'images\\tracker\\icon_openapp.png';

        return (
            <div>
                <img src={buttonSrc} alt='Open de app!' className='iconhand trackerButton' onClick={(event) => {event.stopPropagation(); this.handleClick();}}/>
                <img src={buttonTextSrc} alt='Open de app!' className='iconhand trackerButtonText' onClick={(event) => {event.stopPropagation(); this.handleClick();}}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(OpenAppButton);