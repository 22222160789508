import React, {Component} from "react"

class ReserverenView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/reserveren/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Reserveer de IJskar</h2>
                <p>Wil je zeker zijn en onze ijskar niet missen? Reserveer de ijskar dan op voorhand!</p>
                <p>Vul het reservatieformulier in. Je krijgt een gedetailleerde offerte en bevestiging via e-mail.</p>

                <div class="text-center" style={{margin: "2rem"}}>
                    <a href="https://reservatie.tartiste.be" target="_blank" className="btn btn-primary btn-rounded">Reserveer nu!</a>
                </div>

                <p><b>Wat is het verschil tussen een reservatie en een oproep?</b></p>
                <p>Als je de ijskar roept via de oproepknop weten wij dat je interesse hebt in een ijsje van de ijskar. Als het lukt in de planning en past in onze route komen we graag langs. Maar dit is zéker GEEN garantie. Liever op safe spelen? Kies dan voor <a href="https://reservatie.tartiste.be" target="_blank">reservatie</a>!</p>
            </div>
        )
    }
}

export default ReserverenView